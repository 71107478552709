import moment, { Moment } from 'moment';
import React, {
    DependencyList,
    useLayoutEffect,
} from 'react';
import {
    formatDate,
    parseDate,
} from '@frontend/jetlend-core/src/formatters/formatUtils';

export const CHART_LABEL_COLOR = '#A2A8AD';
export const CHART_AXIS_COLOR = '#EBEEEF';
export const CHART_ANNOTATION_COLOR = '#2CAC8D';

export const CHART_GRID_OPTIONS: ApexGrid = {
    padding: {
        left: 0,
        right: 0,
        top: -10,
        bottom: -26,
    },
    borderColor: CHART_AXIS_COLOR,
    strokeDashArray: 4,
    xaxis: { lines: { show: true } },
    yaxis: { lines: { show: true } },
};

export const CHART_STATES_OPTIONS: ApexStates = {
    hover: {
        filter: {
            type: 'darken',
            value: 0.85,
        },
    },
};

export type ApiSeriesSelector<TApiModel> = (point: TApiModel) => any;

export interface ApiSeriesOptions<TApiModel> {
    id?: any;
    title: string;
    titleMobile?: string;
    hint?: string;
    color: string;
    width?: number;
    dash?: number;
    valueSelector?: ApiSeriesSelector<TApiModel>;
}

export interface ApiChartOptions<TApiModel> {
    labelSelector?: ApiSeriesSelector<TApiModel>;
    series: ApiSeriesOptions<TApiModel>[];
}

export type ChartCorridorConfiguration = {
    normalMin?: number;
    normalMax?: number;
}

export interface IExposedProps {
    height?: number;
    hideLegend?: boolean;
    hideSizeSwitcher?: boolean;
    corridor?: ChartCorridorConfiguration;
    includeEmptyPlaceholder?: boolean;
    disableAnimations?: boolean;
    noBorder?: boolean;
    hideYLabels?: boolean;
    area?: boolean;
    disableVisibilityManagement?: boolean;
}

export enum RangeType {
    DAY = 'D.M.y',
    WEEK = 'W',
    MONTH = 'MMM Y',
    QUARTER = 'Q',
}

export function buildChartXAnnotationForAxisLabel(value: string|number, text: string, textAnchor: 'start'|'middle'|'end' = 'middle'): XAxisAnnotations {
    return {
        x: value,
        borderColor: CHART_AXIS_COLOR,
        strokeDashArray: 4,
        label: {
            borderWidth: 0,
            text,
            offsetX: 0,
            offsetY: 20,
            textAnchor,
            position: 'bottom',
            orientation: 'horizontal',
            style: {
                color: CHART_LABEL_COLOR,
                fontSize: '10px',
                background: 'transparent',
            },
        },
    };
}

export function buildChartYAnnotation(value: number, text: string): YAxisAnnotations {
    return {
        y: value,
        borderColor: CHART_AXIS_COLOR,
        strokeDashArray: 4,
        label: {
            borderWidth: 0,
            text,
            offsetX: -5,
            style: {
                color: CHART_LABEL_COLOR,
                fontSize: '12px',
                background: 'transparent',
            },
        },
    };
}

export function buildPointAnnotation(x: number, y: number, color: string, text: string, isMobileScreen = false, isNotDesktop = false, topPosition = false): PointAnnotations {
    const offsetY = !isMobileScreen && !isNotDesktop ? 45 : 35;
    const offsetYTopPosition = !isMobileScreen && !isNotDesktop ? -7 : -5;
    const desktopFontSize = isNotDesktop ? '12px' : '14px';

    return {
        x,
        y,
        marker: {
            strokeColor: color,
        },
        label: {
            borderColor: 'transparent',
            orientation: 'horizontal',
            borderRadius: !isMobileScreen && !isNotDesktop ? 12 : 6,
            offsetY: topPosition ? offsetYTopPosition : offsetY,
            style: {
                color: '#FFFFFF',
                background: color,
                fontSize: !isMobileScreen ? desktopFontSize : '11px',
                fontWeight: 600,
                padding: !isMobileScreen && !isNotDesktop
                    ? {
                        top: 10,
                        bottom: 10,
                        left: 20,
                        right: 20,
                    }
                    : {
                        top: 6,
                        bottom: 6,
                        left: 8,
                        right: 8,
                    },
            },
            text,
        },
    };
}

export function getChartDateRangeType(size = 0, maxSeriesLength = 0): RangeType {
    if (size === 0) {
        if (maxSeriesLength >= 1.5 * 366) {
            return RangeType.QUARTER;
        }

        return RangeType.MONTH;
    }

    if (size < 35) {
        return RangeType.DAY;
    }

    if (size < 200) {
        return RangeType.WEEK;
    }

    return RangeType.MONTH;
}

function getDateCompareFormat(size = 0, maxSeriesLength = 0) {
    return getChartDateRangeType(size, maxSeriesLength);
}

function getStartDateOfRange(date: Moment, size = 0) {
    const rangeType = getChartDateRangeType(size);
    switch (rangeType) {
    case RangeType.DAY:
        return date;
    case RangeType.WEEK:
        return date.startOf('week').unix() * 1000;
    case RangeType.MONTH:
        return date.startOf('month').unix() * 1000;
    default:
        return date;
    }
}

function withNormalizePointDate(fn, size) {
    return (...args) => {
        const point = fn(...args);
        point.date = getStartDateOfRange(moment(point.date), size);
        return point;
    };
}

function isRangeFilled(points: any[], size = 0) {
    const rangeType = getChartDateRangeType(size);
    switch (rangeType) {
    case RangeType.DAY:
        return points.length > 0;
    case RangeType.WEEK:
        return points.length === 7;
    case RangeType.MONTH:
        return points.length > 25;
    }
}

const REDUCER_IGNORE_DATA_FIELDS = ['idx', 'date'];

export const sumAreaAccFn = (points: any[]) => {
    const point = {
        idx: points[0].idx,
        date: points[0].date,
    };

    points.forEach(p => {
        Object.keys(p).forEach(field => {
            if (!REDUCER_IGNORE_DATA_FIELDS.includes(field)) {
                point[field] = (point[field] || 0.0) + (Number.parseFloat(p[field]) || 0.0);
            }
        });
    });

    return point;
};

export const endDataAreaAccFn = (points: any[]) => {
    const sorted = points.reverse();

    const point = {
        idx: sorted[0].idx,
        date: sorted[0].date,
    };

    sorted.forEach(p => {
        Object.keys(p).forEach(field => {
            if (!REDUCER_IGNORE_DATA_FIELDS.includes(field)) {
                if (typeof point[field] === 'undefined') {
                    const parsed = Number.parseFloat(p[field]);
                    if (typeof parsed === 'number' && !Number.isNaN(parsed)) {
                        point[field] = parsed;
                    }
                }
            }
        });
    });

    return point;
};

export const firstDataAreaAccFn = (points: any[]) => {
    const sorted = points;

    const point = {
        idx: sorted[0].idx,
        date: sorted[0].date,
    };

    sorted.forEach(p => {
        Object.keys(p).forEach(field => {
            if (!REDUCER_IGNORE_DATA_FIELDS.includes(field)) {
                if (typeof point[field] === 'undefined') {
                    const parsed = Number.parseFloat(p[field]);
                    if (typeof parsed === 'number' && !Number.isNaN(parsed)) {
                        point[field] = parsed;
                    }
                }
            }
        });
    });

    return point;
};

export interface IReduceAreaDateSeriesOptions {
    // TODO Refactor to extract all options to that interface
    sliceFromStart?: boolean;
}

const DEFAULT_REDUCE_AREA_DEATE_SERIES_OPTIONS: Required<IReduceAreaDateSeriesOptions> = {
    sliceFromStart: false,
};

export function reduceAreaDateSeries(series: any[], labelSelector: (point: any) => any, size = 0, accFn?: (points: any[]) => any, maxSeriesLength?: number, options?: IReduceAreaDateSeriesOptions): any[] {
    if (!series) {
        return series;
    }

    const opt: IReduceAreaDateSeriesOptions = {
        ...DEFAULT_REDUCE_AREA_DEATE_SERIES_OPTIONS,
        ...(options || {}),
    };

    const aggregatePointsFn = accFn || sumAreaAccFn;

    let points: any[] = [];

    const dateCompareKey = getDateCompareFormat(size, typeof maxSeriesLength === 'number' ? maxSeriesLength : series.length);
    const slicedSeries = opt.sliceFromStart
        ? series.slice(0, size)
        : series.slice(-size);

    return slicedSeries.reduce<any[]>((result, item, index) => {
        if (index === 0) {
            points.push(item);
            return result;
        }

        const prevDate = parseDate(labelSelector(slicedSeries[index - 1]))?.format(dateCompareKey);
        const currentDate = parseDate(labelSelector(slicedSeries[index]))?.format(dateCompareKey);
        if (prevDate !== currentDate) {
            if (points.length > 0) {
                result.push(aggregatePointsFn(points));
                points = [];
            }

            points.push(item);
        } else {
            points.push(item);
        }

        if (index === slicedSeries.length - 1 && points.length > 0) {
            result.push(aggregatePointsFn(points));
            points = [];
        }

        return result;
    }, []);
}

export function buildMinAndMaxForDataSeries(data: any[]) {
    if (!data) {
        return {
            minY: 0,
            maxY: 1,
        };
    }

    const minY = data.reduce((prev, point) => {
        const fields = Object.keys(point).filter(field => !REDUCER_IGNORE_DATA_FIELDS.includes(field));

        return Math.min(
            ...(fields.map(field => Number.parseFloat(point[field]))),
            prev,
            0.0
        );
    }, Number.MAX_VALUE) * 1.2;

    const maxY = data.reduce((prev, point) => {
        const fields = Object.keys(point).filter(field => !REDUCER_IGNORE_DATA_FIELDS.includes(field));

        return Math.max(
            ...fields.map(field => Number.parseFloat(point[field])),
            prev
        );
    }, Number.MIN_VALUE) * 1.2;

    return {
        minY,
        maxY,
    };
}

export function buildBarChartXAxisFormatter(chartDateRangeType: RangeType) {
    return (value: string): string|undefined => {
        if (chartDateRangeType === RangeType.QUARTER) {
            const p = parseDate(value);
            if (p?.get('Q') === 1) {
                return p.format('Y');
            }

            return undefined;
        }

        if (chartDateRangeType === RangeType.WEEK) {
            const currentDate = parseDate(value);

            if (currentDate && (currentDate.date() <= 7)) {
                return currentDate.format('MMM\'YY');
            }

            return undefined;
        }

        if (chartDateRangeType === RangeType.MONTH) {
            const p = parseDate(value);
            if (p && (p.get('month') % 3 === 0)) {
                return parseDate(value)?.format('Q\\KYY');
            }

            return undefined;
        }

        return formatDate(value);
    };
}

export function useCustomGridEnhancer(chartRef: React.MutableRefObject<any>, enhancer: (line: SVGLineElement, index: number) => void, deps: DependencyList = []): void {
    useLayoutEffect(() => {
        const chartElement = chartRef.current?.chart;
        if (!chartElement) {
            return;
        }

        const tick = () => {
            const chartArea: SVGGElement = chartElement.getChartArea();
            const verticalGridLines: SVGLineElement[] = Array.from(chartArea.querySelectorAll('.apexcharts-grid > .apexcharts-gridlines-vertical > line'));

            verticalGridLines.forEach((line, index) => enhancer(line, index));
        };

        const intervalId = setInterval(tick, 1000);
        tick();

        return () => {
            clearInterval(intervalId);
        };
    }, [ chartRef, ...deps ]);
}

export function useChartGridEnhancer(isCategoriesAxis: boolean, categories: string[], chartRef: React.MutableRefObject<any>, xAxisLabelFormatter: (value: string) => string): void {
    useLayoutEffect(() => {
        if (!isCategoriesAxis) {
            return;
        }

        const chartElement = chartRef.current?.chart;
        if (!chartElement) {
            return;
        }

        const tick = () => {
            let chartArea: SVGGElement | null = null;
            try {
                chartArea = chartElement.getChartArea();
                if (!chartArea) {
                    return;
                }
            } catch {
                return;
            }

            const verticalGridLines: SVGLineElement[] = Array.from(chartArea.querySelectorAll('.apexcharts-grid > .apexcharts-gridlines-vertical > line'));

            if (verticalGridLines.length < categories.length) {
                // Chart rendering not complete yet
                return;
            }

            categories.forEach((d, index) => {
                const label = xAxisLabelFormatter(d);
                if (!label) {
                    verticalGridLines[index].style.opacity = '0.0';

                }
            });
        };

        const intervalId = setInterval(tick, 1000);
        tick();

        return () => {
            clearInterval(intervalId);
        };
    }, [ chartRef, isCategoriesAxis, categories ]);
}

export const isTopPositionPoint = (points: PointAnnotations[], currentPoint: number): boolean => points.some(item => {
    const diff = currentPoint - item.y;
    return (diff < 0.10 && diff > 0) || (diff > -0.10 && diff < 0);
});