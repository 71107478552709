import {
    A1_RATINGS_GROUP,
    A2_RATINGS_GROUP,
    B1_RATINGS_GROUP,
    B2_RATINGS_GROUP,
    C1_RATINGS_GROUP,
    C2_RATINGS_GROUP,
} from '@frontend/jetlend-core/src/models/rating';
import {
    RATINGS_LIST,
    Rating,
} from '@app/models/rating';
import {
    IInvestmentCalculatorFormValues,
    IInvestmentCalculatorResult,
    IInvestmentChartPoint,
} from '@app/models/investors/investors';
import { AutoInvestStrategyType } from '@app/models/autoinvest';

export function ratingToId(rating: Rating): number {
    return RATINGS_LIST.indexOf(rating);
}

function buildInvestmentSeries(baseCapitalAmount: number, monthlyTopUpAmount: number, baseYieldRate: number, monthNumber: number, extraVolatile = 0.0): IInvestmentChartPoint[] {
    const minYieldRate = baseYieldRate * (1 - baseYieldRate - extraVolatile);
    const maxYieldRate = baseYieldRate * (1 + baseYieldRate);

    const baseYieldRateInMonth = baseYieldRate / 12;
    const minYieldRateInMonth = minYieldRate / 12;
    const maxYieldRateInMonth = maxYieldRate / 12;

    const result: IInvestmentChartPoint[] = [];

    let capital = baseCapitalAmount;
    let minCapital = capital + capital * minYieldRateInMonth;
    let maxCapital = capital + capital * maxYieldRateInMonth;
    let minIncome= capital * minYieldRateInMonth;
    let maxIncome = capital * maxYieldRateInMonth;

    result.push({
        month: 0,
        capital,
        minCapital,
        minIncome,
        maxIncome,
        maxCapital,
        profitRate: 0,
        minProfitRate: 0,
        maxProfitRate: 0,
    });

    for (let month = 1; month <= monthNumber; month++) {
        const isLowYear = month % 24 > 12;

        capital += capital * (isLowYear ? (minYieldRateInMonth - extraVolatile / 12) : (maxYieldRateInMonth + extraVolatile / 12)) + monthlyTopUpAmount;

        // capital += (isLowYear ? maxCapital : minCapital)
        //     * (isLowYear ? (minYieldRateInMonth - extraVolatile / 12) : (maxYieldRateInMonth + extraVolatile / 12))
        //     + monthlyTopUpAmount;

        minCapital += minCapital * minYieldRateInMonth + monthlyTopUpAmount;
        maxCapital += maxCapital * maxYieldRateInMonth + monthlyTopUpAmount;
        minIncome = (capital + monthlyTopUpAmount*(month-1)) * minYieldRateInMonth + minIncome;
        maxIncome = (capital + monthlyTopUpAmount*(month-1)) * maxYieldRateInMonth + maxIncome;

        result.push({
            month,
            capital,
            minCapital,
            maxCapital,
            minIncome,
            maxIncome,
            profitRate: (capital - baseCapitalAmount - monthlyTopUpAmount * month) / baseCapitalAmount,
            minProfitRate: (minCapital - baseCapitalAmount - monthlyTopUpAmount * month) / baseCapitalAmount,
            maxProfitRate: (maxCapital - baseCapitalAmount - monthlyTopUpAmount * month) / baseCapitalAmount,
        });
    }

    return result;
}

const STRATEGY_RATING_GROUPS = {
    [AutoInvestStrategyType.Conservative]: [...A1_RATINGS_GROUP, ...A2_RATINGS_GROUP, ...B1_RATINGS_GROUP, ...B2_RATINGS_GROUP],
    [AutoInvestStrategyType.Balanced]: [...A1_RATINGS_GROUP, ...A2_RATINGS_GROUP, ...B1_RATINGS_GROUP, ...B2_RATINGS_GROUP, ...C1_RATINGS_GROUP],
    [AutoInvestStrategyType.Aggressive]: [...B2_RATINGS_GROUP, ...C1_RATINGS_GROUP, ...C2_RATINGS_GROUP],
    [AutoInvestStrategyType.Venture]: [...C1_RATINGS_GROUP, ...C2_RATINGS_GROUP],
};

const STRATEGY_VOLATILE = {
    [AutoInvestStrategyType.Conservative]: 0.03,
    [AutoInvestStrategyType.Balanced]: 0.09,
    [AutoInvestStrategyType.Aggressive]: 0.15,
    [AutoInvestStrategyType.Venture]: 0.15,
};

export function evaluateInvestment(values: IInvestmentCalculatorFormValues): IInvestmentCalculatorResult {
    if (!values?.ratingsInfo) {
        return null;
    }

    const yields = [];

    const ratingsSlice = values?.ratingsInfo?.filter(item => STRATEGY_RATING_GROUPS[values?.strategy]?.includes(item.rating));

    ratingsSlice.forEach(rating => {
        const pd = rating.pd;
        const ead = 0.60;
        const lgd = 0.95;
        const el = pd * ead * lgd;

        yields.push(rating.interest_rate - el);
    });

    const averageYieldRate = yields.reduce((a, b) => a + b, 0) / yields.length;
    const monthNumber = values.termInYears * 12;
    const jetlendInvestmentChart = buildInvestmentSeries(
        values.initialAmount,
        values.monthlyTopUpAmount,
        averageYieldRate,
        monthNumber,
        STRATEGY_VOLATILE[values.strategy]
    );

    const jetlendLastPoint = jetlendInvestmentChart[jetlendInvestmentChart.length - 1];

    return {
        minExpectedAmount: jetlendLastPoint.minCapital,
        maxExpectedAmount: jetlendLastPoint.maxCapital,
        minIncome: jetlendLastPoint.minIncome,
        maxIncome: jetlendLastPoint.maxIncome,
        minExpectedYieldRate: averageYieldRate * (1 - averageYieldRate),
        maxExpectedYieldRate: averageYieldRate * (1 + averageYieldRate),

        termInYears: values.termInYears,

        jetlendChart: jetlendInvestmentChart,
        stocksChart: buildInvestmentSeries(values.initialAmount, values.monthlyTopUpAmount, 0.18, monthNumber, 0.3),
        bondsChart: buildInvestmentSeries(values.initialAmount, values.monthlyTopUpAmount, 0.12, monthNumber, 0.1),
        depositsChart: buildInvestmentSeries(values.initialAmount, values.monthlyTopUpAmount, 0.08, monthNumber),
        realEstatesChart: buildInvestmentSeries(values.initialAmount, values.monthlyTopUpAmount, 0.05, monthNumber),
    };
}
