import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import { ReactNode } from 'react';
import styles from './HistoryEvent.module.scss';

interface IProps {
    year: string;
    title: ReactNode;
    titleDescription?: string;
    description: string;
    isLast: boolean;
}

const HistoryEvent : React.FC<IProps> = props => {
    const {
        year,
        title,
        titleDescription,
        description,
        isLast,
    } = props;
    return (
        <div className={styles['event']}>
            <div className={styles['year']}>{year}</div>
            <div className={styles['title-wrapper']}>
                <div className={styles['title']}>{title}</div>
                {titleDescription && <div className={styles['title-description']}>{titleDescription}</div>}
            </div>
            <div className={styles['description']}>{description}</div>
            <div className={buildClassNames(styles, ['event-line', isLast && 'event-line--tail'])} />
        </div>
    );
};

export default HistoryEvent;

