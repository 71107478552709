import React from 'react';
import Tooltip from '@frontend/jetlend-web-ui/src/ui/TooltipIcon/Tooltip';
import Image from '@app/components/server/Image/Image';
import styles from './PartnersItem.module.scss';
import { PartnerItemApiModel } from '@app/models/common/common';

const PartnersItem: React.FC<PartnerItemApiModel> = props => (
    <Tooltip title={props.title} disableWrapper>
        <div className={styles['container']}>
            <Image
                src={props.image}
                alt=""
                fill
                className={styles['image']}
            />
        </div>
    </Tooltip>
);

export default PartnersItem;