'use client';

import { formatPercentExt } from '@frontend/jetlend-core/src/formatters/formatUtils';
import useSimpleApiHandler from '@frontend/jetlend-core/src/hooks/useSimpleApiHandler';
import { memo } from 'react';
import styles from './InvestmentCalculator.module.scss';
import { investorsJetLendStatisticsHandler } from '@app/ducks/investors/investors';


const InvestmentCalculatorNotes = memo(() => {
    const [stats] = useSimpleApiHandler(investorsJetLendStatisticsHandler);

    return (
        <>
            <p className={styles['grey-text']}>
                1. Фактическая доходность может выходить за значения указанного диапазона.
            </p>
            <p className={styles['grey-text']}>
                2. Для расчета графика используются следующие значения средней ожидаемой годовой доходности: акции 18%,
                облигации 12%, депозиты 8%, доходность жилой недвижимости в Москве 5%, краудлендинг в зависимости от уровня риска:
                от {formatPercentExt(stats?.min_interest_rate, {
                    fractionDigits: 1,
                    addSpace: false,
                })}
                до {formatPercentExt(stats?.max_interest_rate, {
                    fractionDigits: 1,
                    addSpace: false,
                })}.
            </p>
        </>
    );
});

export default InvestmentCalculatorNotes;
