import {
    IMainPageFeatureBlockApiModel,
    IMainPageFeatureType,
    MainPageFeaturesSectionApiModel,
} from '@app/models/mainPage/mainPage.';

export type FeatureSectionType = 'chart' | 'conditions' | 'advantages' | 'history' | 'links' | 'null'

export const getSectionType = (section: MainPageFeaturesSectionApiModel): FeatureSectionType => {
    const firstBlockType = getBlockType(section.blocks[0]);

    return firstBlockType;
};

const getBlockType = (block: IMainPageFeatureBlockApiModel): FeatureSectionType => {
    switch (block.id) {
    case IMainPageFeatureType.YEAR_2019:
    case IMainPageFeatureType.YEAR_2020:
    case IMainPageFeatureType.YEAR_2021:
    case IMainPageFeatureType.YEAR_2022:
    case IMainPageFeatureType.YEAR_2023:
    case IMainPageFeatureType.YEAR_2024:
        return 'history';
    case IMainPageFeatureType.CONVENIENT_PRODUCT:
    case IMainPageFeatureType.TRUSTED_PRODUCT:
    case IMainPageFeatureType.TRUSTED_PRODUCT_2:
        return 'advantages';
    case IMainPageFeatureType.PRODUCT_LINKS:
        return 'links';
    case IMainPageFeatureType.SCORING_TIME:
    case IMainPageFeatureType.LIMIT:
    case IMainPageFeatureType.INTEREST_RATE:
    case IMainPageFeatureType.REVOLTING_LINE:
        return 'conditions';
    case IMainPageFeatureType.PROFITABILITY:
        return 'chart';
    default:
        return 'null';
    }
};
