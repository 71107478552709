import React from 'react';
import Tooltip from '../../ui/TooltipIcon/Tooltip';
import {
    buildClassNames,
    mergeClassNames,
} from '@ui/utils/classNameUtils';
import styles from './propertyItem.module.scss';

export type PropertyItemType = 'default' | 'success' | 'warning' | 'danger';
export type PropertyItemSize = 'smallest' | 'small' | 'normal'| 'large' | 'giant';
export type PropertyItemAlign = 'left' | 'center' | 'right';
export type PropertyItemValueType = 'default' | 'bold' | 'light';
export type PropertyItemTitleType = 'default' | 'light' | 'bold';

export interface IProps {
    type?: PropertyItemType;
    title: JSX.Element | React.ReactNode;
    value: JSX.Element | React.ReactNode;
    hint?: JSX.Element | React.ReactNode;
    size?: PropertyItemSize;
    align?: PropertyItemAlign;
    className?: string;
    titleClassName?: string;
    valueClassName?: string;
    noPadding?: boolean;
    noPaddingY?: boolean;
    inverted?: boolean;
    valueType?: PropertyItemValueType;
    titleType?: PropertyItemTitleType;
}

const PropertyItem: React.FC<IProps> = ({
    type = 'default',
    title,
    value,
    size = 'normal',
    align = 'left',
    hint = undefined,
    className,
    noPadding,
    noPaddingY,
    inverted,
    valueType,
    titleType,
    valueClassName: externalValueClassName,
    titleClassName: externalTitleClassName,
}) => {
    const containerClassName = mergeClassNames([
        buildClassNames(styles, [
            'container',
            `container--type-${type}`,
            `container--size-${size}`,
            `container--align-${align}`,
            inverted && 'container--inverted',
            noPadding && 'container--no-padding',
            noPaddingY && 'container--no-padding-y',
        ]),
        className,
    ]);

    const titleClassName = mergeClassNames([
        buildClassNames(styles, [
            'title',
            titleType && `title--type-${titleType}`,
        ]), externalTitleClassName]);

    const valueClassName = mergeClassNames([
        buildClassNames(styles, [
            'value',
            valueType && `value--type-${valueType}`,
        ]),  externalValueClassName]);

    function getTooltipPosition() {
        switch (align) {
        case 'left':
            return 'bottom-start';
        case 'right':
            return 'bottom-end';
        default:
            return 'bottom';
        }
    }

    return (
        <Tooltip title={hint} distance={-10} position={getTooltipPosition()}>
            <div className={containerClassName}>
                <div className={titleClassName}>
                    {title}
                </div>
                <div className={valueClassName}>
                    {value}
                </div>
            </div>
        </Tooltip>
    );
};

export default PropertyItem;