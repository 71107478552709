import React from 'react';
import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import styles from './Section.module.scss';

export type Tags = 'h1' |'h2'
interface IProps {
    title: string;
    mobileTitle: string;
    tag: Tags;
}

const SectionTitle = (props: IProps) => {
    const {
        tag = 'h2',
        title,
        mobileTitle,
    } = props;

    return (
        <>
            {React.createElement(
                tag,
                {
                    className: buildClassNames(styles, [
                        'section__header-title',
                        'section__header-title--desktop',
                        // TODO Think about text auto-resize logic
                        title?.length >= 45 && 'section__header-title--long',
                    ]),
                },
                title
            )}
            <div
                className={buildClassNames(styles, [
                    'section__header-title',
                    'section__header-title--mobile',
                    // TODO Think about text auto-resize logic
                    mobileTitle?.length >= 45 && 'section__header-title--long',
                ])}
            >
                {mobileTitle}
            </div>
        </>
    );
};

export default SectionTitle;