'use client';

import { useState } from 'react';
import styles from './InvestorsLiquidityDomTable.module.scss';
import InvestorsLiquidityDomTableRow from './InvestorsLiquidityDomTableRow';

export interface ILiquidityDomRecord {
    id: number;
    ytm: number;
    count: number;
    price: number;
    amount: number;
}

const DEFAULT_RECORDS: ILiquidityDomRecord[] = [
    {
        id: 1,
        price: 0.994,
        ytm: 0.1834,
        count: 157,
        amount: 157,
    },
    {
        id: 2,
        price: 0.993,
        ytm: 0.1853,
        count: 201,
        amount: 201,
    },
    {
        id: 3,
        price: 0.992,
        ytm: 0.1868,
        count: 286,
        amount: 286,
    },
    {
        id: 4,
        price: 0.991,
        ytm: 0.1912,
        count: 208,
        amount: -208,
    },
    {
        id: 5,
        price: 0.990,
        ytm: 0.1928,
        count: 142,
        amount: -142,
    },
    {
        id: 6,
        price: 0.989,
        ytm: 0.1934,
        count: 110,
        amount: -110,
    },
];

const getMaxAmount = (records: ILiquidityDomRecord[]) => {
    const maxPositiveAmount = records.reduce((acc, item) => item.amount > 0 ? Math.max(acc, item.amount) : acc, 0.0);

    const maxNegativeAmount = records.reduce((acc, item) => item.amount < 0 ? Math.max(acc, Math.abs(item.amount)) : acc, 0.0);

    return Math.max(Math.abs(maxPositiveAmount), Math.abs(maxNegativeAmount));
};

const InvestorsLiquidityDomTable: React.FC = props => {
    const [ records, setRecords ] = useState(DEFAULT_RECORDS);

    // useInterval(() => {
    //     const newRecords = DEFAULT_RECORDS.map(record => {
    //         const delta = record.amount * 0.2;
    //         const step = Math.trunc(Math.random() * delta) - delta * 0.5;
    //         const v = record.amount + step;

    //         return {
    //             ...record,
    //             amount: v,
    //             count: v,
    //         };
    //     });

    //     setRecords(newRecords);
    // }, 5000, []);

    const maxAmount = getMaxAmount(records);

    return (
        <table className={styles['table']}>
            <tbody>
                {records.map(item => (
                    <InvestorsLiquidityDomTableRow
                        key={item.id}
                        item={item}
                        maxAmount={maxAmount}
                    />
                ))}
            </tbody>
        </table>
    );
};

export default InvestorsLiquidityDomTable;