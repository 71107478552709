export enum AboutPlatformType {
    DIRECTORS = 'directors',
    EMPLOYEE = 'employee',
    FEATURES = 'features',
}

interface IBaseSection {
    type: string;
    title: string;
    description: string;
    hidden: boolean;
    show_registration_form: boolean;
}

export enum Department {
    ALL = 'all',
    RISKS = 'risks',
    UNDERWRITING = 'underwriting',
    INVESTORS = 'investors',
    BORROWER = 'borrower',
    DEVELOPMENT = 'development',
    FINANCE = 'finance',
    MARKETING = 'marketing',
    LEGAL = 'legal',
    HR = 'hr',
}

export interface IBasePerson {
    photo: string | false;
    name: string;
    department?: Exclude<Department, 'all'>;
    position: string;
    email: string;
    description?: string;
}

export interface IDirectorsSection extends IBaseSection {
    ceo: IBasePerson;
    others: IBasePerson[];
}

export interface IEmployeeSection extends IBaseSection {
    persons: IBasePerson[];
}

export interface IFeatureBlock {
    id: string;
    title: string;
    description: string;
    value: string;
    value_description: string;
    image: string;
}

export interface IFeaturesSection extends IBaseSection {
    blocks: IFeatureBlock[];
}

export interface IMarkupSection extends IBaseSection {
    content: string;
    footer: string;
    image: string;
}