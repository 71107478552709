import React from 'react';

export interface IProps<T> extends React.HTMLAttributes<T> {
    tag?: keyof React.ReactHTML;
    className?: string;
    markup?: string;
}

export default function HtmlMarkup<T extends HTMLElement>({
    tag,
    markup,
    ...rest
}: IProps<T>) {
    return React.createElement(tag, {
        ...rest,
        dangerouslySetInnerHTML: {
            __html: markup,
        },
    });
}
