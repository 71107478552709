import { buildClassNames } from '@frontend/jetlend-web-ui/src/utils/classNameUtils';
import {
    formatPercentCss,
    formatPercentExt,
} from '@frontend/jetlend-core/src/formatters/formatUtils';
import AnimatedCounter from '@frontend/jetlend-web-ui/src/ui/AnimatedCounter/AnimatedCounter';
import styles from './InvestorsLiquidityDomTable.module.scss';
import { ILiquidityDomRecord } from './InvestorsLiquidityDomTable';

export type RowProps = {
    item: ILiquidityDomRecord;
    maxAmount: number;
}

const InvestorsLiquidityDomTableRow: React.FC<RowProps> = props => {

    const {
        item,
        maxAmount,
    } = props;

    const positiveBarStyle = {
        width: formatPercentCss(item.amount / maxAmount),
    };

    const negativeBarStyle = {
        width: formatPercentCss(-item.amount / maxAmount),
    };

    return (
        <tr>
            <td width="45%" className={buildClassNames(styles, ['column', 'column--left'])}>
                <div>
                    {item.amount < 0 && (
                        <>
                            <div
                                className={buildClassNames(styles, ['bar', 'bar--left', 'bar--success'])}
                                style={negativeBarStyle}
                            />
                            <div className={buildClassNames(styles, ['bar-text', 'bar-text--left', 'bar-text--success'])}>
                                <AnimatedCounter value={Math.abs(item.count)} render={v => v.toFixed(0)} />
                            </div>
                        </>
                    )}
                </div>
            </td>
            <td width="10%" className={buildClassNames(styles, ['column', 'column--center'])}>
                <div>
                    <div>{formatPercentExt(item.price, {
                        fractionDigits: 2,
                        addSpace: false,
                    })}
                    </div>
                    <div>{formatPercentExt(item.ytm, {
                        fractionDigits: 2,
                        addSpace: false,
                    })}
                    </div>
                </div>
            </td>
            <td width="45%" className={buildClassNames(styles, ['column', 'column--right'])}>
                <div>
                    {item.amount > 0 && (
                        <>
                            <div
                                className={buildClassNames(styles, ['bar', 'bar--right', 'bar--danger'])}
                                style={positiveBarStyle}
                            />
                            <div className={buildClassNames(styles, ['bar-text', 'bar-text--right', 'bar-text--danger'])}>
                                <AnimatedCounter value={Math.abs(item.count)} render={v => v.toFixed(0)} />
                            </div>
                        </>
                    )}
                </div>
            </td>
        </tr>
    );
};

export default InvestorsLiquidityDomTableRow;