'use client';

import { useMemo } from 'react';
import { ISectionBasedProps } from '@app/models/common/common';
import { MainPageFeaturesSectionApiModel } from '@app/models/mainPage/mainPage.';
import YieldRateFeature from './YieldRateFeature/YieldRateFeature';
import ConditionsFeature from './ConditionsFeature/ConditionsFeature';
import AdvantagesFeature from './AdvantagesFeature/AdvantagesFeature';
import HistoryFeature from './HistoryFeature/HistoryFeature';
import LinksFeature from './LinksFeature/LinksFeature';
import {
    FeatureSectionType,
    getSectionType,
} from './MainPageFeaturesSection.logic';

type Props = ISectionBasedProps<MainPageFeaturesSectionApiModel>;

const MainPageFeaturesSection: React.FC<Props> = props => {
    const { section } = props;
    const sectionType = getSectionType(section);
    const config = useMemo<Record<FeatureSectionType, JSX.Element | null>>(() => ({
        'chart': <YieldRateFeature {...props} />,
        'conditions': <ConditionsFeature {...props} />,
        'advantages': <AdvantagesFeature {...props} />,
        'history': <HistoryFeature {...props} />,
        'links': <LinksFeature {...props} />,
        'null': null,
    }), [props]);

    return config[sectionType];
};

export default MainPageFeaturesSection;