'use client';

import { formatPercent } from '@frontend/jetlend-core/src/formatters/formatUtils';
import PropertyItem, {
    PropertyItemAlign,
    PropertyItemSize,
} from '@frontend/jetlend-web-ui/src/components/PropertyItem/PropertyItem';
import PropertiesGrid from '@frontend/jetlend-web-ui/src/components/PropertiesGrid/PropertiesGrid';
import {
    isMobile,
    isSmallMobile,
    isTablet,
    useWindowSize,
} from '@frontend/jetlend-web-ui/src/utils/responsive';
import { IInvestorsGeographySectionApiModel } from '@app/models/investors/investors';
import styles from './InvestorsGeographySection.module.scss';
import Image from '../../Image/Image';
import { ISectionBasedProps } from '@app/models/common/common';

type Props = ISectionBasedProps<IInvestorsGeographySectionApiModel>

function getPropertySize(screenWidth: number): PropertyItemSize {
    if (isSmallMobile(screenWidth)) {
        return 'small';
    }

    if (isMobile(screenWidth)) {
        return 'large';
    }

    if (isTablet(screenWidth)) {
        return 'large';
    }

    return 'giant';
}

const InvestorsGeographySection: React.FC<Props> = props => {
    const {
        section: {
            regions,
            image,
        },
    } = props;

    const { width: screenWidth } = useWindowSize();

    const otherRegionsValue = regions.reduce((acc, region) => acc - parseFloat(region.part), 1);
    const propertySize = getPropertySize(screenWidth);

    const alignment: PropertyItemAlign = isMobile(screenWidth) ? 'center' : 'left';

    return (
        <div>
            <PropertiesGrid justify="between">
                {props.section.regions.map(region => (
                    <PropertyItem
                        key={region.title}
                        title={region.title}
                        value={formatPercent(region.part)}
                        align={alignment}
                        inverted
                        size={propertySize}
                        noPadding
                        className={styles['property-item']}
                    />
                ))}
                <PropertyItem
                    title="Др. города"
                    value={formatPercent(otherRegionsValue)}
                    align={alignment}
                    inverted
                    size={propertySize}
                    noPadding
                    className={styles['property-item']}
                />
            </PropertiesGrid>
            <div className={styles['borrowers-map']}>
                <Image
                    src={image}
                    alt=""
                    fill
                />
            </div>
        </div>
    );
};

export default InvestorsGeographySection;