import React from 'react';
import Modal from '@frontend/jetlend-web-ui/src/ui/modals/Modal';
import StrategiesTable from './StrategiesTable';
import InvestmentCalculatorFooterButtons from '../InvestmentCalculatorFooterButtons/InvestmentCalculatorFooterButtons';

export interface IProps {
    /**
     * Состояние модального окна
     */
    isOpen: boolean;
    /**
     * Функция для закрытия модального окна
     */
    onClose: VoidFunction;
}

/**
 * Компонент для отрисовки модального окна с таблицей стратегий
 */
const StrategiesTableModal:React.FC<IProps> = props => {
    const {
        isOpen,
        onClose,
    } = props;

    return (
        <Modal
            title="Инвестиционные показатели"
            isOpen={isOpen}
            onClosed={onClose}
            closable
            size="wide"
            footer={<InvestmentCalculatorFooterButtons />}
        >
            <StrategiesTable />
        </Modal>
    );
};

export default StrategiesTableModal;
