import { BaseSectionWithBlocks } from '../common/common';

export enum MainPageSectionType {
    FEATURES = 'features',
    HOW_IT_WORKS = 'how_it_works',
    STATISTICS = 'statistics',
}

export interface IStatisticsBlockApiModel {
    title: string;
    value: string;
    description: string;
}

export interface IMainPageSectionBlockApiModel {
    title: string;
    value?: string;
    description?: string;
    value_description?: string;
    image?: string;
}

export type BaseSectionWithBlocksAndDescription<T> = BaseSectionWithBlocks<T> & {
    description: string;
}

export type MainPageFeaturesSectionApiModel = BaseSectionWithBlocksAndDescription<IMainPageFeatureBlockApiModel>

export interface IMainPageFeatureBlockApiModel extends IMainPageSectionBlockApiModel {
    id: IMainPageFeatureType;
}

export enum IMainPageFeatureType {
    PROFITABILITY = 'profitability',
    SCORING_TIME = 'scoring-time',
    LIMIT = 'limit',
    INTEREST_RATE = 'interest_rate',
    REVOLTING_LINE = 'revolting-line',
    CONVENIENT_PRODUCT = 'convenient_product',
    TRUSTED_PRODUCT = 'trusted_product',
    TRUSTED_PRODUCT_2 = 'trusted_product_2',
    YEAR_2019 = 'year_2019',
    YEAR_2020 = 'year_2020',
    YEAR_2021 = 'year_2021',
    YEAR_2022 = 'year_2022',
    YEAR_2023 = 'year_2023',
    YEAR_2024 = 'year_2024',
    PRODUCT_LINKS = 'product_links',
}